const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/download"],
    exact: true,
    component: "Download",
  },
  {
    path: ["/privacy"],
    exact: true,
    component: "Privacy",
  },
  {
    path: ["/terms"],
    exact: true,
    component: "Terms",
  },
];

export default routes;
