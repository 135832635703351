import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  LogoContainer,
  SocialContainer,
  Para,
  Large,
} from "./styles";
import { PngIcon } from "../../common/PngIcon";
import { Button } from "../../common/Button";

const Footer = ({ t }: any) => {
  return (
    <FooterSection>
      <Container>
        <Row justify="space-between">
          <Col>
            <NavLink to="/">
              <LogoContainer>
                <PngIcon
                  src="Logo.png"
                  aria-label="homepage"
                  width="171px"
                  height="32px"
                />
              </LogoContainer>
            </NavLink>
            <Title>- Watch App</Title>
            <NavLink style={{ width: "180px" }} to="/download">
              <Button>{t("Download")}</Button>
            </NavLink>
          </Col>
          <Col lg={6} md={8} sm={12} xs={12}>
            <Title>{t("Contact")}</Title>
            <Para>
              {t(`Do you have any question? Feel free to reach out.`)}
            </Para>
            <SocialContainer>
              <a href="mailto:clockwork.watch.app@gmail.com">
                <PngIcon
                  src="mail.png"
                  aria-label="mail"
                  width="48px"
                  height="48px"
                />
              </a>
              <a href="https://instagram.com/clockwork.watch.app">
                <PngIcon
                  src="insta.png"
                  aria-label="instagram"
                  width="48px"
                  height="48px"
                  style={{ marginLeft: "1em" }}
                />
              </a>
            </SocialContainer>
          </Col>
          <Col lg={6} md={8} sm={12} xs={12}>
            <Title>{"Policy and terms"}</Title>
            <Large to="/privacy" left="true">
              {t("Privacy policy")}
            </Large>
            <Large to="/terms" left="true">
              {"Terms and conditions"}
            </Large>
          </Col>
        </Row>
        <Row>
          <Para>Appfred AS © 2022, all rights reserved.</Para>
        </Row>
      </Container>
    </FooterSection>
  );
};

export default withTranslation()(Footer);
