import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { PngIcon } from "../../common/PngIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

interface LocationState {
  from: {
    pathname: string;
  };
}

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const location = useLocation<LocationState>();
  const history = useHistory();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      // TODO: Test this!
      if (location.pathname !== "") {
        console.log('Pathname not === ""');
        history.push("/home");
      }
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    const navigateToDownloadPage = () => {
      history.push("/download");
    };

    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("information")}>
          <Span>{t("Information")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("measure")}>
          <Span>{t("Measure")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("extra")}>
          <Span>{t("Extra")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={navigateToDownloadPage}
        >
          <Span>
            <Button>{t("Download")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <PngIcon src="Logo.png" width="171px" height="32px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
